import React from 'react';
import './Screens.css'; // Import the CSS file

function FPGA() {
  return (
    <div className="project-page">
      <h2 className="title">Under Maintenace</h2>
    </div>
  );
}

export default FPGA;
