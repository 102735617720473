import React, { useEffect, useState, useRef, useCallback } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { loadData } from "../utils/DataLoader"; // Your data loader utility
import "./World.css";  // Import your CSS for styling

const Earth = ({ electricData, gasData }) => {
  const canvasRef = useRef(null);
  const [graphData, setGraphData] = useState("electric");
  const [electricFile, setElectricFile] = useState(null);
  const [gasFile, setGasFile] = useState(null);

  const scene = useRef(new THREE.Scene()).current;
  const camera = useRef(new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 10)).current;
  const renderer = useRef(null);
  const controls = useRef(null);

  // Function to update bars based on data
  const updateBars = useCallback((data) => {
    // Clear previous bars
    scene.children.forEach((child) => {
      if (child.type === "Mesh" && child.name !== "WorldMapMesh") {
        scene.remove(child);
      }
    });

    // Scale factors for correct positioning
    const scaleX = 2;  // Longitude scaling
    const scaleZ = 1;  // Latitude scaling
    const heightFactor = 1000000;  // Adjust height scale factor for bars

    data.forEach(([latitude, longitude, value]) => {
      if (value === -9999) return; // Skip invalid data points

      // Calculate height based on value (make sure it's a reasonable height)
      const barHeight = Math.max(value / heightFactor, 0.05);  // Minimum height is 0.05 to avoid tiny bars

      // Bar geometry
      const barGeometry = new THREE.BoxGeometry(0.01, barHeight, 0.01);
      const barMaterial = new THREE.MeshLambertMaterial({
        color: getColorForValue(value), // Set color based on value
      });
      // const bar = new THREE.Mesh(barGeometry, barMaterial);

      // Map latitude/longitude to X/Z position on the map
      const lon = longitude;  // Longitude maps to X axis
      const lat = latitude;   // Latitude maps to Z axis

      // Position bar on map (use scale factors to position correctly)
      // bar.position.set(lon / 180 * scaleX, -barHeight / 2, lat / 90 * -scaleZ); // Y position is half the bar height
      const bar1 = new THREE.Mesh(barGeometry, barMaterial);
      // Position the first bar on the map (upward direction)
      bar1.position.set(
        lon / 180 * scaleX,
        barHeight / 2, // Upward position
        lat / 90 * -scaleZ
      );
  
      // Create the second bar (going down)
      const bar2 = new THREE.Mesh(barGeometry, barMaterial);
      // Position the second bar on the map (downward direction)
      bar2.position.set(
        lon / 180 * scaleX,
        -barHeight / 2, // Downward position
        lat / 90 * -scaleZ
      );
      // Add the bar to the scene
      scene.add(bar1);
      scene.add(bar2);

    });
  }, [scene]);

  // Load the data (electric and gas) and set it to state
  useEffect(() => {
    if (canvasRef.current) {
      renderer.current = new THREE.WebGLRenderer({ canvas: canvasRef.current });
      renderer.current.setSize(window.innerWidth, window.innerHeight);
      camera.position.set(0, 500, 600); // Move camera back for better view

      controls.current = new OrbitControls(camera, canvasRef.current);
      controls.current.enableDamping = true;
      controls.current.enablePan = false;
      controls.current.minDistance = 1.5;
      controls.current.maxDistance = 3;
      controls.current.update();

      scene.background = new THREE.Color("black");

      // Add light sources
      const light = new THREE.AmbientLight(0x404040);
      scene.add(light);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(1, 1, 1).normalize();
      scene.add(directionalLight);

      // Load world map texture and display it on a plane
      const loader = new THREE.TextureLoader();
      loader.load("/world.jpg", (texture) => {
        const geometry = new THREE.PlaneGeometry(4, 2);  // Plane for the world map
        const material = new THREE.MeshBasicMaterial({ 
          map: texture,
          side: THREE.DoubleSide
         });
        const worldMapMesh = new THREE.Mesh(geometry, material);
        worldMapMesh.rotation.x = -Math.PI / 2;  // Rotate the plane by 90 degrees on the X-axis to face upwards
        worldMapMesh.position.set(0, 0, 0.0);  // Make sure the map is positioned at the origin

        worldMapMesh.name = "WorldMapMesh";  // Name for identification
        scene.add(worldMapMesh);
      });

      // Load electric and gas data
      loadData("/data/electricData.json").then((electricFileData) => {
        setElectricFile(electricFileData);
        loadData("/data/gasData.json").then((gasFileData) => {
          setGasFile(gasFileData);
        });
      });

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        controls.current.update();
        renderer.current.render(scene, camera);
      };

      animate();
    }
  }, [electricData, gasData, camera, scene]);

  // Resize event listener
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      renderer.current.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update bars whenever the data changes
  useEffect(() => {
    if (electricFile && gasFile) {
      updateBars(graphData === "electric" ? electricFile : gasFile);
    }
  }, [electricFile, gasFile, graphData, updateBars]);

  // Color generation based on value
  const getColorForValue = (value) => {
    const color = new THREE.Color();
    const scaledValue = Math.min(value / 1000, 1);  // Scale value between 0 and 1
    color.setHSL(scaledValue, 0.8, 0.5);  // Use HSL for coloring
    return color;
  };

  // Handle the button clicks to switch between electric and gas
  const handleButtonClick = (type) => {
    setGraphData(type);
  };

  return (
    <div className="earth-container">
      <div className="button-container">
        <button onClick={() => handleButtonClick("electric")} className={graphData === "electric" ? "active" : ""}>
          Electric
        </button>
        <button onClick={() => handleButtonClick("gas")} className={graphData === "gas" ? "active" : ""}>
          Gas
        </button>
      </div>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default Earth;
