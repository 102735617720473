import React from 'react';

function Resume() {
  return (
    <div className="resume-page">
      <h1>Resume</h1>
      <p>Download my <a href="resume.pdf" target="_blank">Resume</a> (PDF).</p>
      <h2>Experience</h2>
      <p>Software Engineer at XYZ</p>
      <h2>Education</h2>
      <p>B.Sc. in Mechatronics Engineering</p>
    </div>
  );
}

export default Resume;
