import React from 'react';
import './Screens.css'

function Contact() {
  return (
    <div className="contact-page">
      <h1 className="projects-title">Contact Me</h1>
      <p className="projects-description">
        <li>Email: joshkim432@gmail.com</li>
        <li>Phone: 714-341-7222</li>
        {/* <li>LinkedIn: <a href="https://linkedin.com/in/imjkim" target="_blank">linkedin.com/in/imjkim</a></li> */}
      </p>
    </div>
  );
}

export default Contact;
