import React, { useEffect, useRef, useState} from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

const EarthScene = () => {
  const mountRef = useRef(null);
  const earthRef = useRef();
  const camera = useRef(null);
  const carRef = useRef();
  const isDraggingRef = useRef(false);
  const isOverEarthRef = useRef(false);
  const previousMousePositionRef = useRef({ x: 0, y: 0 });
  const gasRef = useRef(false);
  const brakeRef = useRef(false);
  const gascounter = useRef(0);
  const brakecounter = useRef(0);
  const speed = 0.0003;
  const speedstep = 0.0001;
  const decelerationTimeout = useRef(null);
  const renderer = useRef();
  const animationId = useRef(null);
  const raycaster = useRef(new THREE.Raycaster());
  const mouse = useRef(new THREE.Vector2());
  const gasPedalRef = useRef(null);
  const brakePedalRef = useRef(null);
  const [brakePressed, setBrakePressed] = useState(false);
  const [gasPressed, setGasPressed] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const isLoadedRef = useRef(isLoaded);
  const [latitude, setLatitude] = useState('0.00 ° N');
  const [longitude, setLongitude] = useState('0.00 ° E');
  const [currspeed, setSpeed] = useState(0); // Speed in meters per second (m/s)
  // const [acceleration, setAcceleration] = useState(0); // Acceleration in meters per second squared (m/s²)

  const [location, setLocation] = useState('');
  // const lastFetchTime = useRef(Date.now());
  // const fetchInterval = 1000; // 1 second
  useEffect(() => {
    const scene = new THREE.Scene();
    camera.current = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.current.position.set(0, 3, 6);
    const mount = mountRef.current;
    if (mount) {
      renderer.current = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.current.setSize(window.innerWidth, window.innerHeight);
      mount.appendChild(renderer.current.domElement);
    }

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/decoder/');

    const loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);

    let earthLoaded = false;
    let carLoaded = false;
    let sceneLoaded = false;
    const intervalTime = 1000; // 1 second interval

    const checkLoaded = () => {
      if (earthLoaded && carLoaded && sceneLoaded) {
        setIsLoaded(true); // Trigger a re-render
        isLoadedRef.current = isLoaded;
        console.log('Both models loaded');
      }
    };

    // Load Earth model
    loader.load('/blenderearth.glb', (gltf) => {
      earthRef.current = gltf.scene;
      scene.add(earthRef.current);
      const screenWidth = window.outerWidth;
      if (screenWidth < 768) {
        earthRef.current.scale.set(0.005, 0.005, 0.005); // Scale down for mobile
      } else {
        earthRef.current.scale.set(0.007, 0.007, 0.007);
      }
      earthRef.current.position.set(0, 4.3, -2);
      earthRef.current.rotation.x = -0.15;
      earthRef.current.rotation.y = -1.9253;
      earthLoaded = true;
      checkLoaded();
    }, undefined, (error) => {
      console.error('An error happened while loading the GLB model:', error);
    });

    // Load Car model
    loader.load('/car.glb', (gltf) => {
      carRef.current = gltf.scene;
      const screenWidth = window.outerWidth;
      if (screenWidth < 768) {
        carRef.current.position.set(0, 3.6, 3);
      } else {
        carRef.current.position.set(0, 3.7, 3);
      }
      // carRef.current.position.set(0, 6.2, 1);
      carRef.current.scale.set(0.1, 0.1, 0.1);
      carRef.current.rotation.y = -Math.PI / 2;
      carRef.current.rotation.x = (50 * Math.PI) / 180; // Tilt upwards by 10 degrees
      carRef.current.rotation.z = -0.6;
      scene.add(carRef.current);
      carLoaded = true;
      checkLoaded();
    }, undefined, (error) => {
      console.error('An error happened while loading the car model:', error);
    });

    const cleanup = () => {
      // Clean up the models and textures
      scene.children.forEach(child => {
        if (child instanceof THREE.Mesh) {
          if (child.geometry) child.geometry.dispose();
          if (child.material) {
            child.material.dispose();
          }
        }
      });
  
      // Clean up DracoLoader
      dracoLoader.dispose();
      
      // Dispose of WebGLRenderer
      if (renderer.current) {
        renderer.current.dispose();
      }
    };

    const ambientLight = new THREE.AmbientLight(0x404040, 2);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 10, 7.5).normalize();
    scene.add(directionalLight);
    sceneLoaded = true;
    checkLoaded();

    let lastTime = 0;
    const animate = (time) => {

      animationId.current = requestAnimationFrame(animate);
      if (isLoadedRef.current) {
        if (earthRef.current) {
          earthRef.current.rotation.x = normalizeAngle(earthRef.current.rotation.x);
          earthRef.current.rotation.y = normalizeAngle(earthRef.current.rotation.y);
          let gasForce = Math.log(1 + gascounter.current) * speedstep; // Logarithmic scaling for gas force
          let brakeForce = speedstep * Math.pow(1.01, brakecounter.current);
          let totalSpeed = speed + gasForce - brakeForce;
          totalSpeed = Math.max(0, Math.min(totalSpeed, 0.02)); // Clamp speed
          let scaledSpeed = totalSpeed * 1000000;
          // Update state
          setSpeed(scaledSpeed);
          earthRef.current.rotation.x += totalSpeed;

          // Define an offset based on your observations
          const longitudeOffset = 180; // Adjust this value based on your observations

          // Calculate latitude and longitude

          let lat = -(earthRef.current.rotation.x * 180) / Math.PI; // Convert radians to degrees
          let lon = (earthRef.current.rotation.y * 180) / Math.PI; // Convert radians to degrees
          // console.log("latitude is ", lat);

          // Apply the offset to longitude
          lon += longitudeOffset;
          let normalizedLat;

          // Transition logic for latitude
          if (lat >= 90) {
              normalizedLat = 90 - (lat - 90); // Transition to 90° S
          } else if (lat <= -90) {
              normalizedLat = -90 + (-90 - lat); // Transition to 90° N
          } else {
              normalizedLat = lat; // Keep within -90 to 90
          }
          
          // Adjust normalized latitude for seamless transition
          if (normalizedLat > 90) {
              normalizedLat = 90 - (normalizedLat - 90); // Wrap to 90° S
          } else if (normalizedLat < -90) {
              normalizedLat = -90 + (-90 - normalizedLat); // Wrap to 90° N
          }
          let normalizedLon = ((lon + 180) % 360) - 180;

          // Adjust normalized longitude to match expected values
          if (normalizedLon > 180) {
            normalizedLon -= 360; // Adjust to stay within the range
          }

          normalizedLat *= -1;
          let deltaTime  = time - lastTime;
          if (deltaTime >= 5000) {
            // Call getLocationFromCoordinates every 1 second
            console.log("getting location from ",normalizedLat.toFixed(1), normalizedLon.toFixed(1) );
            //getLocationFromCoordinates(normalizedLat.toFixed(1), normalizedLon.toFixed(1));

            // Update lastTime to the current time
            lastTime = time;
          }
          updateCoordinates(normalizedLat, normalizedLon);
        }
      }
      if (gasRef.current) {
        gascounter.current ++;
      } else {
        gascounter.current = Math.max(0, gascounter.current - 1);
      }

      if (brakeRef.current) {
        brakecounter.current++;
      } else {
        brakecounter.current = Math.max(0, brakecounter.current - 1);
      }
      if (renderer.current && camera.current) {
        renderer.current.render(scene, camera.current);
      }
    };

    // Resize handler for window resize events
    const handleResize = () => {
      // Check if camera and renderer are initialized
      if (camera.current && renderer.current) {
        const width = window.innerWidth;
        const height = window.innerHeight;

        // Update the camera aspect ratio
        camera.current.aspect = width / height;
        camera.current.updateProjectionMatrix();

        // Resize the renderer to fit the new window size
        renderer.current.setSize(width, height);
      }
    };

    const updateCoordinates = (lat, lon) => {
      const latDirection = lat >= 0 ? 'N' : 'S';
      const longDirection = lon >= 0 ? 'W' : 'E';
    
      setLatitude(Math.abs(lat).toFixed(2) + ' ° ' + latDirection);
      setLongitude(Math.abs(lon).toFixed(2) + ' ° ' + longDirection);
    };
    const getLocationFromCoordinates = async (latitude, longitude) => {
      // const apiUrl = `https://imjkim-portfolio.cloudfunctions.net/api/reverse?lat=${latitude}&lon=${longitude}`;
      const apiUrl = `https://api-zho6rj3ooa-uc.a.run.app/api/reverse?lat=${latitude}&lon=${longitude}`;
      try {
          const response = await fetch(apiUrl, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
              },
          });
          if (!response.ok) {
              throw new Error('Network response was not ok: ' + response.statusText);
          }
          const data = await response.json();
          // Assuming data contains the address info
          const city = data.address?.city || data.address?.town || data.address?.village || 'Unknown City';
          const country = data.address?.country || 'Unknown Country';
          const locationMessage = country !== 'Unknown Country' ? `${city}, ${country}` : 'Invalid location or ocean';
          setLocation(locationMessage); // Update state with the location message
          console.log("location was  ",locationMessage );

      } catch (err) {
          console.log("Error fetching location: ", err);
          setLocation('Under Maintenance');
      }
  };

    const normalizeAngle = (angle) => {
      return angle % (2 * Math.PI);
    };
    // Start the animation
    animate();
    const onTouchStart = (event) => {
      if (!isLoadedRef.current) return; // Prevent interactions if not loaded
      event.preventDefault();
      const touch = event.touches[0];
      if (!touch) return;
      // Set the initial mouse position based on touch coordinates
      raycaster.current.setFromCamera(mouse.current, camera.current);
      const intersects = raycaster.current.intersectObject(earthRef.current, true);
    
      if (intersects.length > 0) {
        isDraggingRef.current = true;
        document.body.style.cursor = 'grabbing';
        previousMousePositionRef.current = { x: touch.clientX, y: touch.clientY };
      }
    };
    const onTouchMove = (event) => {
      if (!isLoadedRef.current) return; // Prevent interactions if not loaded
      event.preventDefault();
      const touch = event.touches[0];
      if (!touch) return;
      mouse.current.x = (touch.clientX / window.innerWidth) * 2 - 1;
      mouse.current.y = -(touch.clientY / window.innerHeight) * 2 + 1;
    
      // Check if earthRef.current is defined
      if (earthRef.current) {
        raycaster.current.setFromCamera(mouse.current, camera.current);
        const intersects = raycaster.current.intersectObject(earthRef.current, true);
    
        if (intersects.length > 0 && !isDraggingRef.current) {
          isOverEarthRef.current = true;
          document.body.style.cursor = 'grab';
        } else if (!isDraggingRef.current) {
          isOverEarthRef.current = false;
          document.body.style.cursor = 'auto';
        }
      }
    
      if (isDraggingRef.current) {
        const deltaMove = {
          x: touch.clientX - previousMousePositionRef.current.x,
          y: touch.clientY - previousMousePositionRef.current.y,
        };
        if (earthRef.current) {
          earthRef.current.rotation.y += deltaMove.x * 0.01;
          earthRef.current.rotation.x += deltaMove.y * 0.01;
        }
        previousMousePositionRef.current = { x: touch.clientX, y: touch.clientY };
      }
    
      // // Gas pedal area check (same logic as for mouse)
      // if (gasPedalRef.current) {
      //   const gasRect = gasPedalRef.current.getBoundingClientRect();
      //   const isGasOver = (
      //     touch.clientX >= gasRect.left &&
      //     touch.clientX <= gasRect.right &&
      //     touch.clientY >= gasRect.top &&
      //     touch.clientY <= gasRect.bottom
      //   );
      //   if (isGasOver) {
      //     console.log('touch on gas');
      //   } else {
      //     gasRef.current = false;
      //   }
      // }
    
      // // Brake pedal area check (same logic as for mouse)
      // if (brakePedalRef.current) {
      //   const brakeRect = brakePedalRef.current.getBoundingClientRect();
      //   const isBrakeOver = (
      //     touch.clientX >= brakeRect.left &&
      //     touch.clientX <= brakeRect.right &&
      //     touch.clientY >= brakeRect.top &&
      //     touch.clientY <= brakeRect.bottom
      //   );
      //   if (isBrakeOver) {
      //     console.log('touch on brake');
      //   } else {
      //     brakeRef.current = false;
      //   }
      // }
    };
    const onTouchEnd = () => {
      if (!isLoadedRef.current) return; // Prevent interactions if not loaded
    
      isDraggingRef.current = false;
      gasRef.current = false;
      brakeRef.current = false;
    
      if (isOverEarthRef.current) {
        document.body.style.cursor = 'grab';
      } else {
        document.body.style.cursor = 'auto';
      }
    };    
    const onMouseDown = (event) => {
      if (!isLoadedRef.current) return; // Prevent interactions if not loaded
      raycaster.current.setFromCamera(mouse.current, camera.current);
      const intersects = raycaster.current.intersectObject(earthRef.current, true);

      if (intersects.length > 0) {
        isDraggingRef.current = true;
        document.body.style.cursor = 'grabbing';
        previousMousePositionRef.current = { x: event.clientX, y: event.clientY };
      }
    };

    const onMouseMove = (event) => {
      if (!isLoadedRef.current) return; // Prevent interactions if not loaded
      mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
    
      // Check if earthRef.current is defined
      if (earthRef.current) {
        raycaster.current.setFromCamera(mouse.current, camera.current);
        const intersects = raycaster.current.intersectObject(earthRef.current, true);
    
        if (intersects.length > 0 && !isDraggingRef.current) {
          isOverEarthRef.current = true;
          document.body.style.cursor = 'grab';
        } else if (!isDraggingRef.current) {
          isOverEarthRef.current = false;
          document.body.style.cursor = 'auto';
        }
      }
    
      if (isDraggingRef.current) {
        const deltaMove = {
          x: event.clientX - previousMousePositionRef.current.x,
          y: event.clientY - previousMousePositionRef.current.y,
        };
        if (earthRef.current) {
          earthRef.current.rotation.y += deltaMove.x * 0.01;
          earthRef.current.rotation.x += deltaMove.y * 0.01;
        }
        previousMousePositionRef.current = { x: event.clientX, y: event.clientY };
      }
      if (gasPedalRef.current) {
        const gasRect = gasPedalRef.current.getBoundingClientRect();
        const isGasOver = (
          event.clientX >= gasRect.left &&
          event.clientX <= gasRect.right &&
          event.clientY >= gasRect.top &&
          event.clientY <= gasRect.bottom
        );
        if (isGasOver) {
          console.log('mouse on gas');
        } else{
          gasRef.current = false;
        }
      } 
      if (brakePedalRef.current) {
        // Check if mouse is over brake pedal
        const brakeRect = brakePedalRef.current.getBoundingClientRect();
        const isBrakeOver = (
          event.clientX >= brakeRect.left &&
          event.clientX <= brakeRect.right &&
          event.clientY >= brakeRect.top &&
          event.clientY <= brakeRect.bottom
        );
        if (isBrakeOver) {
          console.log('mouse on brake');
        }else{
          brakeRef.current = false;
        }
      } 
    };
    
    const onMouseUp = () => {
      if (!isLoadedRef.current) return; // Prevent interactions if not loaded
      isDraggingRef.current = false;
      gasRef.current = false;
      brakeRef.current = false;
      if (isOverEarthRef.current) {
        document.body.style.cursor = 'grab';
      } else {
        document.body.style.cursor = 'auto';
      }
    };
    const onKeyDown = (event) => {
      if (event.key === 'b') {
        handleBrakeMouseDown();
      }
      if (event.key === 'g') { // Use 'g' for gas as an example
        handleGasMouseDown();
      }
    };
  
    const onKeyUp = (event) => {
      if (event.key === 'b') {
        handleBrakeMouseUp();
      }
      if (event.key === 'g') {
        handleGasMouseUp();
      }
    };
    //mobile
    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onTouchEnd);
    //mouse
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    // Add the resize event listener
    window.addEventListener('resize', handleResize);

  
    return () => {
      cancelAnimationFrame(animationId.current);
      // mobile devices
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
      // mouse
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('resize', handleResize);

      if (mount) {
        mount.removeChild(renderer.current.domElement);
      }
      cleanup();
    };
  }, [isLoaded]); // No dependencies needed for this effect

  const handleGasMouseDown = () => {
    setGasPressed(true); // Set the gas button as pressed
    brakeRef.current = false;
    gasRef.current = true;
  };

  const handleGasMouseUp = () => {
    setGasPressed(false); 
    gasRef.current = false;
    if (decelerationTimeout.current) clearTimeout(decelerationTimeout.current);
    decelerationTimeout.current = setTimeout(() => {
      if (gascounter.current > 0) gascounter.current--;
    }, 100);
  };

  const handleBrakeMouseDown = () => {
    setBrakePressed(true); // Set the brake button as pressed
    gasRef.current = false;
    brakeRef.current = true;
  };

  const handleBrakeMouseUp = () => {
    setBrakePressed(false);
    brakeRef.current = false;
    if (decelerationTimeout.current) clearTimeout(decelerationTimeout.current);
    decelerationTimeout.current = setTimeout(() => {
      if (brakecounter.current > 0) brakecounter.current--;
    }, 100);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute space vertically
        height: '100vh', // Full viewport height
        width: '100vw', // Full viewport width
        position: 'relative',
      }}
    >     
    {!isLoaded && (
      <div
        style={{
          color: 'white',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10,
        }}
      >
        Loading...
      </div>
    )}
    <div
      ref={mountRef}
      style={{
        flex: 1, // Allow it to take up remaining space
        width: '100%',
        height: '75vh',
        overflow: 'hidden',
        opacity: isLoaded ? 1 : 0.5,
        position: 'relative',
      }}
    />
{isLoaded && (
  <div
    className="location-container"
    style={{
      position: 'absolute', // Fix it to the viewport
      top: '10px', // Position it 10px from the top edge
      left: '10px', // Position it 10px from the left edge (this was the original idea)
      color: 'white',
      zIndex: 10,
      padding: '10px',
      textAlign: 'left',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional background for readability
      borderRadius: '8px', // Optional: rounded corners

    }}
  >
    <div>Latitude, Longitude: </div>
    <div>{latitude}, {longitude}</div>

    <div>Location: Under Maintenance </div>

  </div>
)}

{isLoaded && (
  <div
    className="speed-container"
    style={{
      position: 'absolute', // Position relative to the viewport
      top: '10px', // Position it 10px from the top edge
      right: '10px', // Position it 10px from the left edge (this was the original idea)
      color: 'white',
      zIndex: 10,
      padding: '10px',
      textAlign: 'left',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
      borderRadius: '8px', // Rounded corners
      maxWidth: '250px', // Optional: max width to avoid stretching
    }}
  >
    <div>Speed: {currspeed.toFixed(2)} km/s</div> {/* Displaying Speed with two decimal places */}
    {/* <div>Acceleration: {acceleration.toFixed(2)} km/s²</div> Displaying Acceleration with two decimal places */}
  </div>
)}
    {isLoaded && (
      <div
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 10,
          marginBottom: '10vh', // Leave space from bottom
        }}
      >
      <div
        className="brake-container"
      >            
      <span
        className="brake-label"
      > 
        Brake (B)
      </span>
        <img 
          ref={brakePedalRef}
          src="brakepedal.png" 
          alt="Brake" 
          onMouseDown={handleBrakeMouseDown} 
          onMouseUp={handleBrakeMouseUp} 
          onTouchStart={(e) => {
            e.preventDefault(); 
            handleBrakeMouseDown(e); 
          }} 
          onTouchEnd={(e) => {
            e.preventDefault(); 
            handleBrakeMouseUp(e); 
          }} 
          draggable="false"  // Disable image dragging
          onContextMenu={(e) => e.preventDefault()}  
          className={(brakePressed && brakeRef.current) ? 'pressed' : ''}  

          style={{
            width: '60px',
            maxWidth: '80px', // Max width to avoid scaling too large
            cursor: 'url(/shoe.png) 16 16, auto',  
            touchAction: 'none', 
            userSelect: 'none', 
            pointerEvents: 'auto', 
            WebkitTouchCallout: 'none',  
            WebkitUserDrag: 'none',                                           // Disable drag on Webkit-based browsers (e.g., Safari)
            opacity: (brakePressed && brakeRef.current) ? 0.7 : 1,            // Change opacity to indicate press
            filter: (brakePressed && brakeRef.current) ? 'brightness(0.8)' : 'brightness(1)',
            transition: 'all 0.2s ease', 
          }}
        />
          </div>
          <div
            className="gas-container"
          >
          <span className="gas-label">        
            Gas (G)
          </span>
            <img
            ref={gasPedalRef}
            src="gaspedal.png"
            alt="Gas"
            onMouseDown={handleGasMouseDown}
            onMouseUp={handleGasMouseUp}
            onTouchStart={(e) => {
              e.preventDefault(); 
              handleGasMouseDown(e); 
            }} 
            onTouchEnd={(e) => {
              e.preventDefault(); 
              handleGasMouseUp(e);  
            }} 
            draggable="false"
            onContextMenu={(e) => e.preventDefault()} 
            className={(gasPressed && gasRef.current) ? 'pressed' : ''}  
            style={{
              width: '55px',
              maxWidth: '80px', // Max width to avoid scaling too large

              cursor: 'url(/shoe.png) 16 16, auto',  
              touchAction: 'none', 
              userSelect: 'none',  
              pointerEvents: 'auto', 
              WebkitTouchCallout: 'none',  
              WebkitUserDrag: 'none',                                     // Disable drag on Webkit-based browsers (e.g., Safari)
              opacity: (gasPressed && gasRef.current) ? 0.7 : 1,          // Change opacity to indicate press
              filter: (gasPressed && gasRef.current) ? 'brightness(0.8)' : 'brightness(1)',
              transition: 'all 0.2s ease', 
            }}
          />
          </div>
      </div>
    )}
    </div>
  );
};

export default EarthScene;
