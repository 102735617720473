import React from 'react';
import CarEarth from '../components/CarEarth'; // Make sure to update path
import './LandingPage.css';

function CarStatsPage() {
  const electricData = {
    url: "./data/electriccar.asc",
    name: "Electric Cars"
  };

  const gasData = {
    url: "./data/gascar.asc",
    name: "Gas Cars"
  };
  return (
    <div className="car-page">
      <title>car earth</title>
        <CarEarth electricData={electricData} gasData={gasData}/>
      </div>
  );
}

export default CarStatsPage;
