import React from 'react';
import { Link } from 'react-router-dom';
import './Screens.css';

function Projects() {
  return (
    <div className="projects-page">
      <h1 className="projects-title">Projects</h1>
      <p className="projects-description">Here are some of the projects I’ve worked on:</p>
      <ul className="projects-list">
        <li className="project-item">
          <Link to="/projects/mechatronics" className="project-title">Mechatronics Project</Link>
        </li>
        <li className="project-item">
          <Link to="/projects/iotnetwork" className="project-title">IoT Network</Link>
        </li>
        <li className="project-item">
          <Link to="/projects/fpga" className="project-title">Machine Learning/ Building a SLM</Link>
        </li>
        <li className="project-item">
          <Link to="/projects/fpga" className="project-title">FPGA</Link>
        </li>
      </ul>
    </div>
  );
}

export default Projects;
