import React from 'react';
import './Screens.css'; // Import the CSS file

function Mechatronics() {
  return (
    <div className="project-page">
      <h2 className="title">Mechatronics</h2>
      <section className="about-image-container" style={{ marginTop: '10px' }}>
        <div className="about-me">
          <p>
             In this multi-disciplinary team project, our team designed and built a robot from scratch using a 
          PIC32 Uno32 MIPS microcontroller. We utilized SolidWorks and a laser cutter for precise 
          mechanical fabrication, incorporating an H-drive drivetrain with three omni-wheels and two 
          H-bridges, allowing the robot to travel vertically and horizontally without rotating.
          </p>
        </div>
        <div className="image-container">
          <img src="/mech2.jpg" alt="Mechatronics Diagram" className="project-image2" />
        </div>
      </section>

      <section className="about-image-container">
        <div className="image-container2">
        <a 
        href="https://joshkim432.wixsite.com/robot" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="link"
        >
          Visit our project page
        </a>
        </div>
        <div className="about-me2">
          <p>
          The software was developed in Embedded C with MPLAB X IDE, focusing on sophisticated circuit 
        designs to detect various ranges of frequencies at specific levels. The system included multiple 
        inputs and outputs. We engineered custom PCBs, meticulously soldered circuits, and seamlessly 
        integrated these components into the robot, demonstrating a robust application of both hardware 
        and software engineering principles.
          </p>
        </div>
      </section>

    </div>
  );
}

export default Mechatronics;
