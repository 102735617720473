import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import the CSS for styling

function Navbar() {
  return (
    <nav className="navbar">
      <Link to="/" className="logo">
        <h1>IMJKIM</h1>
      </Link>
      <ul className="nav-links">
        <li><Link to="/aboutme">About Me</Link></li>
        <li><Link to="/carstats">Car Stats</Link></li>
        {/* <li><Link to="/aboutme">About Me</Link></li> */}
        <li><Link to="/projects">Projects</Link></li>
        {/* <li><Link to="/mechatronics">Mechatronics Project</Link></li> */}
        {/* <li><Link to="/resume">Resume</Link></li> */}
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
