import React from 'react';
import './Screens.css';


function AboutMe() {
  return (
    <div className="project-page">
      <h2 className="title">About Me</h2>
      <section className="about-image-container">
        <div className="about-me">
          <p>
              Professional with expertise in embedded software development, unit/HIL testing,
            and development of cross-platform applications. Graduated in 2022 from University of California Santa Cruz
            with a Bachelor of Science in Computer Engineering. Currently pursuing advanced studies
            in Computer Science with a focus on AI and machine learning.
          </p>
        </div>
        <div className="image-container">
          <img src="/niceview2.jpg" alt="Mechatronics Diagram" className="project-image" />
        </div>
      </section>
      <section className="about-image-container reverse">
        
        <div className="about-me2">
          <p>
              My hobbies include taking on light hikes, going to the gym, playing video games, and learning to play 
            the guitar. I was born in Seoul, South Korea, and I have resided in the United States since I was seven, 
            spending most of that time in California. When I have the chance, I enjoy travelling. 
          </p>
        </div>
        <div className="image-container">
          <img src="/niceview1.jpg" alt="Description of Image 1" className="project-image" />
        </div>
      </section>
    </div>
  );
}

export default AboutMe;
