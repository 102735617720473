// export const loadData = async (url) => {
//   try {
//     const response = await fetch(url);
//     if (!response.ok) {
//       throw new Error(`Failed to load data from ${url}`);
//     }
//     const text = await response.text(); // Read the file as text
//     return parseAscData(text); // Parse the .asc raster data
//   } catch (error) {
//     console.error("Error loading data:", error);
//   }
// };
export const loadData = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${url}. Status: ${response.status}`);
    }
    // Log the response content for debugging
    const text = await response.text();
    try {
      return JSON.parse(text);  // Parse the response as JSON
    } catch (jsonError) {
      throw new Error(`Invalid JSON returned from ${url}: ${text}`);
    }
  } catch (error) {
    console.error("Error loading data:", error);
    return [];  // Return empty array in case of an error
  }
};
export const parseAscData = (data) => {
  const lines = data.split("\n");
  const meta = {};
  const values = [];

  // Parse metadata (first 6 lines)
  for (let i = 0; i < 6; i++) {
    const [key, value] = lines[i].split(/\s+/);
    meta[key] = parseFloat(value);
  }

  // Parse the grid data
  for (let i = 6; i < lines.length; i++) {
    const row = lines[i].trim();
    if (row) {
      const cols = row.split(/\s+/).map((val) => parseFloat(val));
      values.push(...cols);
    }
  }

  const { ncols, nrows, xllcorner, yllcorner, cellsize } = meta;

  // Convert grid data into a structured format
  const dataPoints = [];
  for (let i = 0; i < nrows; i++) {
    for (let j = 0; j < ncols; j++) {
      const value = values[i * ncols + j];
      if (value !== -9999) {  // Skip no-data values
        // Adjust the latitude calculation
        const lat = yllcorner + (nrows - i - 0.5) * cellsize; // Correct calculation for latitude
        const lon = xllcorner + (j + 0.5) * cellsize;
        dataPoints.push({ latitude: lat, longitude: lon, value });
      }
    }
  }

  return dataPoints;
};
