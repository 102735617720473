import React from 'react';
import './Screens.css'; // Import the CSS file

function IoTNetwork() {
  return (
    <div className="project-page">
      <h2 className="projects-title">Remote Control of CAN Networks with Headless Server</h2>
      <p className="description">
      In the automotive industry, a lot of systems incorporate CAN network. I have observed that J1939 is used for applications such as drilling, heavy-duty vehicles, and construction.
In this project, we will develop a system using a RockPro64 board running a Linux OS, leveraging a combination of C, C++, and Python programming languages. The user interface will be designed using Qt to provide a graphical user interface (GUI) for remote control and monitoring of Controller Area Network (CAN) networks. The system will communicate with the CAN network through a CAN to USB adapter (such as PeakUSB) to facilitate real-time data exchange.
The project will also integrate MQTT for sending and receiving CAN messages remotely, enabling monitoring and control of the CAN network over a TCP based network. This system will offer comprehensive control and visualization, making it easier for users to manage CAN devices remotely through the RockPro64, with the ability to send and receive commands, visualize data, and manage the network via the GUI.
Many ECUs and controllers utilizing J1939 networks are tailored to specific message protocols, meaning that fine-tuning the system to accommodate different configurations is inevitable. This post aims to introduce the general concept of remote CAN network control and more specifically, my tailored solution for a personal system. Modifications or adjustments may be necessary depending on the specific requirements of different CAN networks.
      </p>   
      <div className="image-container">
        <img src="/iot.png" alt="High Level Diagram" className="project-image2" />
    </div>
    </div>
  );
}

export default IoTNetwork;
